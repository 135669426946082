<template>
  <div>
    <h3 class="v-subheader">Qanday qilib ishlatish kerak?</h3>
  </div>
</template>

<script>
  export default {
    name: 'wiki',
  }
</script>
